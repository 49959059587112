// api.js (or imageApi.js)

import { ENDPOINTS } from './apiEndpoints';

export const fetchImages = async () => {
  try {
    const response = await fetch(ENDPOINTS.IMAGE);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;  // Assuming the data returned is an array of images
  } catch (error) {
    console.error("Error fetching images:", error);
    throw error;  // Re-throw to handle it in the component if needed
  }
};

export const addContact = async (contactData) => {
  try {
    const response = await fetch(ENDPOINTS.CONTACT_ADD, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',  // Sending JSON data
      },
      body: JSON.stringify(contactData),  // Sending the form data as JSON
    });

    // Check if the response is successful
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    // Parse and return the response
    const data = await response.json();
    return data;  // You can use this to check the API response for success or error
  } catch (error) {
    console.error("Error adding contact:", error);
    throw error;  // Re-throw to handle it in the component if needed
  }
};


export const setting = async () => {
  try {
    const response = await fetch(ENDPOINTS.SETTING);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;  // Assuming the video URL is located in `data.data.video`
  } catch (error) {
    console.error("Error fetching video:", error);
    throw error;  // Re-throw to handle it in the component if needed
  }
};