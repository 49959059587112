import React, { useEffect, useState } from "react";
import { setting } from "./api/api";  // Assuming 'setting' is the function that fetches settings, including the video URL.

function Header() {
  const [videoUrl, setVideoUrl] = useState("");

  useEffect(() => {
    // Fetch the video URL when the component mounts
    const fetchVideo = async () => {
      try {
        const response = await setting();  // Call the setting API function
        if (response && response.data && response.data.video) {
          setVideoUrl(response.data.video);  // Set the video URL if available in the response
        } else {
          console.error("Video URL not found in the response");
        }
      } catch (error) {
        console.error("Error fetching video:", error);
      }
    };

    fetchVideo();
  }, []); // Empty dependency array ensures it runs only once when the component mounts

  return (
    <header className="App-header">
       {videoUrl ? (
        <video style={{ height: '100vh', width: '100%' }} controls autoPlay>
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <p>Loading video...</p>
      )}
    </header>
  );
}

export default Header;
