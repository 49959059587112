import "./App.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Gallery from "./Gallery";
import Form from "./Form";
import Header from "./Header";

function App() {
  return (
    <div className="App">
      <Header />
      <Form />
      <Gallery />
    </div>
  );
}

export default App;
