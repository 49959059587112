import React, { useEffect, useState } from "react";
import { fetchImages } from "./api/api";

function Gallery() {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);  // Track loading state

  useEffect(() => {
    const loadImages = async () => {
      try {
        const data = await fetchImages();
        if (data.status_code === '1') {
          setImages(data.data);  // Assuming API returns an array of image objects
        } else {
          console.error("Unexpected API response format");
        }
      } catch (error) {
        console.error("Error fetching images:", error);
      } finally {
        setLoading(false);  // Stop loading when done
      }
    };

    loadImages();
  }, []);

  return (
    <main className="page">
      <section className="container">
        <section className="gallery">
          {loading ? (
            <div className="spinner">
              <i className="fa fa-spinner fa-spin fa-3x"></i> {/* Font Awesome Spinner */}
            </div>
          ) : (
            images.length > 0 ? (
              images.map((image, index) => (
                <figure className="gallery__item" key={index}>
                  <img className="gallery__image" src={image.image} alt={image.alt || "Image"} />
                </figure>
              ))
            ) : (
              <p>No images available.</p>
            )
          )}
        </section>
      </section>
    </main>
  );
}

export default Gallery;
