import React, { useState, useEffect } from "react";
import { addContact, setting } from "./api/api";  // Adjust the import based on your actual file structure
import { ToastContainer, toast } from "react-toastify";  // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css';  // Import the CSS for toast notifications

function Form() {
  const [formData, setFormData] = useState({
    company_name: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    address: ''
  });

  const [loading, setLoading] = useState(false);
  const [settingDetails, setSettingDetails] = useState({
    about: '',
    email: '',
    phone: '',
    address: ''
  });

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Fetch the setting details when the component mounts
  useEffect(() => {
    const fetchSetting = async () => {
      try {
        const response = await setting();  // Call the setting API function
        if (response && response.data) {
          // Set the setting details into state if available
          setSettingDetails({
            about: response.data.about,
            email: response.data.email,
            phone: response.data.phone,
            address: response.data.address
          });
        } else {
          console.error("Setting details not found in the response");
        }
      } catch (error) {
        console.error("Error fetching setting details:", error);
      }
    };

    fetchSetting();
  }, []); // Empty dependency array ensures this runs once when the component mounts

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await addContact(formData);  // Assuming addContact is a function in api.js
      if (response.status_code === '1') {
        toast.success("Contact added successfully!");  // Success toast message
      } else {
        toast.error("Failed to add contact. Please try again.");  // Error toast message
      }
    } catch (err) {
      toast.error("Failed to add contact. Please try again.");  // Error toast message
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <span className="big-circle"></span>
      <img src="img/shape.png" className="square" alt="" />
      <div className="form">
        <div className="contact-info">
          <h3 className="title">Let's get in touch</h3>
          <p className="text">{settingDetails.about || "Lorem ipsum dolor sit amet."}</p>

          <div className="info">
            <div className="information">
              <i className="fas fa-map-marker-alt"></i>
              <p>{settingDetails.address || "92 Cherry Drive Uniondale, NY 11553"}</p>
            </div>
            <div className="information">
              <i className="fas fa-envelope"></i>
              <p>{settingDetails.email || "lorem@ipsum.com"}</p>
            </div>
            <div className="information">
              <i className="fas fa-phone"></i>
              <p>{settingDetails.phone || "123-456-789"}</p>
            </div>
          </div>
        </div>

        <div className="contact-form">
          <span className="circle one"></span>
          <span className="circle two"></span>

          <form onSubmit={handleSubmit} autoComplete="off">
            <h3 className="title">Contact us</h3>
            <div className="input-container">
              <input
                type="text"
                name="company_name"
                className="input"
                value={formData.company_name}
                onChange={handleInputChange}
                placeholder="Company name"
              />
            </div>
            <div className="input-container">
              <input
                type="text"
                name="first_name"
                className="input"
                value={formData.first_name}
                onChange={handleInputChange}
                placeholder="First name"
              />
            </div>
            <div className="input-container">
              <input
                type="text"
                name="last_name"
                className="input"
                value={formData.last_name}
                onChange={handleInputChange}
                placeholder="Last name"
              />
            </div>
            <div className="input-container">
              <input
                type="email"
                name="email"
                className="input"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Email"
              />
            </div>
            <div className="input-container">
              <input
                type="tel"
                name="phone"
                className="input"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder="Phone"
              />
            </div>
            <div className="input-container textarea">
              <textarea
                name="address"
                className="input"
                rows={3}
                value={formData.address}
                onChange={handleInputChange}
                placeholder="Address"
              ></textarea>
            </div>
            <input
              type="submit"
              value={loading ? "Submitting..." : "Send"}
              className="btn"
              disabled={loading}
            />
          </form>
        </div>
      </div>

      {/* Add ToastContainer to render the toasts */}
      <ToastContainer />
    </div>
  );
}

export default Form;
